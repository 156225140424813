<template>
  <TaskForm
    :task="taskList"
    :loading="loading"
    :disabled="loading"
    :status="status"
    @new:task="onTaskNew"
    @edit:task="onTaskEdit"
    @delete:task="onTaskDelete"
    @reposition:task="onTaskReposition"
    @submit="onSubmit"
  />
</template>

<script>
import TaskForm from "./components/TaskForm.vue";
import { hasSnackbarAccess } from "@/mixins/snackbar";
import {
  TASK_LIST_QUERY,
  TASK_LIST_UPDATE,
  TASK_CREATE,
  TASK_UPDATE,
  TASK_DELETE,
  TASK_REPOSITION,
} from "./graphql";

export default {
  name: "EditTaskListView",
  components: { TaskForm },
  mixins: [hasSnackbarAccess],
  props: {
    storeId: {
      type: [String, Number],
      required: true,
    },
    taskListId: {
      type: [String, Number],
      required: true,
    },
  },
  data: () => ({ updating: false, status: null }),
  apollo: {
    taskList: {
      query: TASK_LIST_QUERY,
      variables() {
        return { id: this.taskListId };
      },
      skip() {
        return !this.taskListId;
      },
    },
  },
  computed: {
    loading() {
      return this.$apollo.queries.taskList.loading;
    },
  },
  methods: {
    async onSubmit({ values, resolve, setErrors }) {
      try {
        this.updating = true;
        const response = await this.$apollo.mutate({
          mutation: TASK_LIST_UPDATE,
          variables: { input: values },
        });

        const { errors, taskList } = response.data.updateTaskList;

        if (errors.length) {
          setErrors(errors);
          return;
        }

        this.showSnackbar({
          text: this.$t("daybook.setup.editTaskList.submit.submitSuccess"),
        });
        this.taskList = taskList;
        resolve();
      } catch (error) {
        this.showSnackbar({
          text: this.$t("daybook.setup.editTaskList.submit.submitError", {
            error: error,
          }),
        });
      } finally {
        this.updating = false;
      }
    },
    async onTaskNew({ values, resolve, setErrors }) {
      try {
        const response = await this.$apollo.mutate({
          mutation: TASK_CREATE,
          variables: {
            input: {
              ...values,
              taskListId: this.taskListId,
              id: undefined,
            },
          },
        });

        const { errors, task } = response.data.createTask;

        if (errors.length) {
          setErrors(errors);
          return;
        }

        this.taskList.tasks?.push(task);
        this.showSnackbar({
          text: this.$t("daybook.setup.editTaskList.taskNew.createSuccess", {
            name: task.name,
          }),
        });
        resolve();
      } catch (error) {
        this.showSnackbar({
          text: this.$t("daybook.setup.editTaskList.taskNew.createError", {
            error: error,
          }),
        });
      }
    },
    async onTaskEdit({ values, resolve, setErrors }) {
      try {
        const response = await this.$apollo.mutate({
          mutation: TASK_UPDATE,
          variables: {
            input: { ...values, name: undefined },
          },
        });

        const { errors, task } = response.data.updateTask;

        if (errors.length) {
          setErrors(errors);
          return;
        }

        const taskIndex = this.taskList.tasks.findIndex(
          (x) => x.id === task.id
        );
        this.$set(this.taskList.tasks, taskIndex, task);
        this.showSnackbar({
          text: this.$t("daybook.setup.editTaskList.taskEdit.editSuccess", {
            name: task.name,
          }),
        });
        resolve();
      } catch (error) {
        this.showSnackbar({
          text: this.$t("daybook.setup.editTaskList.taskEdit.editError", {
            error: error,
          }),
        });
      }
    },
    async onTaskDelete({ id, name }) {
      const confirm = await this.$dialog.confirm({
        title: this.$t("daybook.setup.editTaskList.taskDelete.title"),
        message: this.$t("daybook.setup.editTaskList.taskDelete.message", {
          name: name,
        }),
      });

      if (!confirm) {
        return;
      }

      try {
        const response = await this.$apollo.mutate({
          mutation: TASK_DELETE,
          variables: { id },
        });

        const { errors, task } = response.data.deleteTask;

        if (errors.length) {
          setErrors(errors);
          return;
        }

        const taskIndex = this.taskList.tasks.findIndex(
          (x) => x.id === task.id
        );
        this.taskList.tasks?.splice(taskIndex, 1);
        this.showSnackbar({
          text: this.$t("daybook.setup.editTaskList.taskDelete.deleteSuccess", {
            name: name,
          }),
        });
      } catch (error) {
        this.showSnackbar({
          text: this.$t("daybook.setup.editTaskList.taskDelete.deleteError", {
            error: error,
          }),
        });
      }
    },
    async onTaskReposition(values) {
      try {
        this.status = this.$t(
          "daybook.setup.editTaskList.taskReposition.status"
        );
        await this.$apollo.mutate({
          mutation: TASK_REPOSITION,
          variables: {
            storeId: this.storeId,
            taskListId: this.taskListId,
            input: values,
          },
        });
      } catch (error) {
        this.showSnackbar({
          text: this.$t(
            "daybook.setup.editTaskList.taskReposition.repositionError",
            {
              error: error,
            }
          ),
        });
      } finally {
        this.status = null;
      }
    },
  },
};
</script>
